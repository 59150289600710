const initialState = {
  exchangeCoinsRate: {
    buy_market_rate: 0,
    sell_market_rate: 0,
    deposit_items_rate: 0,
    deposit_crypto_rate: 0,
    withdraw_crypto_rate: 0,
  },
  seo: {
    data: [],
    totalCount: 0,
    edit: {},
    redirectToListing: false,
  },
  infoPages: {
    data: [],
    total: 0,
    edit: {},
    redirectToListing: false,
  },
};

const siteSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EXCHANGE_COINS_RATES":
      return {
        ...state,
        exchangeCoinsRate: payload,
      };
    case "GET_SEO_INFO":
      return {
        ...state,
        seo: payload,
      };
    case "GET_INFO_PAGES":
      return {
        ...state,
        infoPages: {
          data: payload.data,
          total: payload.totalCount,
        },
      };
    case "GET_CURRENT_INFO_PAGE":
      return {
        ...state,
        infoPages: {
          ...state.seo,
          edit: payload,
        },
      };
    case "CLEAR_CURRENT_INFO_PAGE":
      return {
        ...state,
        infoPages: {
          ...state.infoPages,
          edit: {},
        },
      };
    case "CLEAR_CURRENT_SEO_INFO":
      return {
        ...state,
        seo: {
          ...state.seo,
          edit: {},
        },
      };
    case "SEO_REDIRECT_TO_LISTING":
      return {
        ...state,
        seo: {
          ...state.seo,
          redirectToListing: payload,
        },
      };
    case "INFO_PAGE_REDIRECT_TO_LISTING":
      return {
        ...state,
        infoPages: {
          ...state.seo,
          redirectToListing: payload,
        },
      };
    default:
      return state;
  }
};

export default siteSettingsReducer;
