// **  Initial State
const initialState = {
  orders: [],
  totalCount: 0,
};

const deliveryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_ORDERS":
      return {
        ...state,
        orders: payload.data,
        totalCount: payload.total_count,
      };

    default:
      return state;
  }
};

export default deliveryReducer;
